import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RHCServiceService } from '../services/RHC-service.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router } from '@angular/router';
import { UpdateRHC, RHCModel } from '../Model/RHCModel';
import { filt, DistrictModel } from '../Model/DistrictModel';
import { ExportService } from '../services/export.service';
import { OrderModule } from 'ngx-order-pipe';
import { CreateDivision } from '../Model/DivisionModel';
import { DHQ } from '../Model/DHQModel';
import { DHQServiceService } from '../services/DHQ-service.service';
import { DivisionService } from '../services/division.service';
import { ProvinceService } from '../services/province.service';
import { CreateProvince } from '../Model/ProvinceModel';
declare var $: any;

@Component({
  selector: 'app-RHC',
  templateUrl: './RHC.component.html',
  styleUrls: ['./RHC.component.css']
})
export class RHCComponent implements OnInit {
  tr: boolean = true;
  districtModel: Array<DistrictModel> = new Array();
  ActiveDistrictModel: any
  RHCList: Array<RHCModel> = new Array();
  showselectedRHC: boolean = false;
  // selectedRHC : RHCModel = new RHCModel();
  upRHC: UpdateRHC = new UpdateRHC();
  p: boolean = null;
  Id: any
  selectedTehsil: number;
  public RHCName: string;
  public RHCPhone: string;
  public RHCAddress: string;
  public DistrictId: number;
  public Division:string;
  public District:string;
  RHCTehsil: string;
  Type: string;
  hiddendata: Array<filt> = new Array();
  isAdmin: boolean = false;
  @Output() headerTitle = new EventEmitter<string>();
  selectedDivisionId: number = 0;
  divisionModel: Array<CreateDivision> = new Array()
  divisiondefModel: Array<any> = new Array();
  districtdefModel: Array<any> = new Array();
  selectedRHCDetails: boolean = false;
  dp: boolean = true
  public Locality1: number;
  showRhcdata: boolean = false;
  selectedRHC: RHCModel = new RHCModel();
  role:any
  districtid:number=0
  provinceModel: Array<CreateProvince> = new Array()
  provincedefModel: Array<any> = new Array();
  isProvinceSelected: boolean = false;
  isDevisionSelected: boolean = false; 
  DHQList: Array<DHQ> = new Array();

  constructor(private division: DivisionService, private Province:ProvinceService, private RHCService: RHCServiceService, private DHQService: DHQServiceService, private genericService: GenericServiceService, private router: Router, private exportService: ExportService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.showselectedRHC = false;
    this.headerTitle.emit("RHC");
    this.selectedTehsil = 0;

    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'DashboardUser' || localStorage.getItem("role") == 'SuperAdmin') {
      this.role = localStorage.getItem("role");
      this.isAdmin = true;
      this.districtModel = [];
      this.divisionModel = [];
      this.provinceModel = [];

      this.Province.getAllProvinceData().subscribe(data => {
        this.provincedefModel = data;
        var ActiveProvinceModel = [];
        this.provincedefModel.forEach(function (value) {
          if (value.IsActive == true) {
            ActiveProvinceModel.push(value);
          }
        },
          this.ProvinceMultiData = ActiveProvinceModel
  
        );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
        });

      this.RHCList = [];
      this.RHCService.getAllRHC(this.districtid,null).subscribe(data => {
        this.RHCList = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
        });

    }
  }
  selectedLab(lab: RHCModel) {
    this.selectedRHC = lab;
    this.selectedRHCDetails = true;
    this.dp = false
  }

  // SelectedDistrictForRHCCreation(id: any){
  //   this.DistrictId = parseInt(id);
  // }

  showSelectedDistrictRHC(id: any) {
    if(id == 0)
      {
        id = this.selectedDivisionId;
      }
    this.DistrictId = parseInt(id);
    this.RHCList = [];
    this.RHCService.getRHCByDictrict(id).subscribe(data => {
      this.RHCList = data;
    },
      error => {
        // console.log(error);
      });
  }
  createRHC() {
    this.RHCService.CreateNewRHC(this.RHCName, this.RHCPhone, this.RHCAddress, this.DistrictId).subscribe((resp) => {
      this.Toastr.success('RHC Created Successfully ');
      $("#createRHCModal").modal("hide");
      this.ngOnInit();
      this.RHCName = '';
      this.RHCAddress = '';
      this.RHCPhone = '';
      this.DistrictId = 0;
    },
      (error) => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  export(reportType: number) {
    if (reportType == 3) {
      this.exportService.exportExcel(this.RHCList, 'RHCs');
    }
  }

  ReverseOrderOfRHC() {
    // reverse the order of allied provider model
    this.RHCList.sort((a, b) => (a.ReferedPatients < b.ReferedPatients) ? 1 : -1)

  }
 
  selectedRHCDetail(RHC: any,) {
    this.selectedRHC = RHC;
    this.showselectedRHC = true;
  }

  UpdateSelectedDistricForRHCCreation(data) {
    this.selectedRHC.DistrictId = parseInt(data);
    // console.log(this.selectedRHC.DistrictId)
  }
  EditRHC() {
    this.upRHC.RHCId = this.selectedRHC.Id;
    this.upRHC.Name = this.selectedRHC.Name;
    this.upRHC.Contact = this.selectedRHC.Contact;
    this.upRHC.DistrictId = this.selectedRHC.DistrictId;
    this.upRHC.IsActive = this.selectedRHC.IsActive;
    // console.log(this.upRHC)
    this.RHCService.UpdateRHC(this.upRHC).subscribe(resp => {
      this.Toastr.success('RHC Updated Successfully ');
      $("#detailRHCModal").modal("hide");
      this.tr = !this.tr
      this.ngOnInit();
      // console.log(resp);
    }, error => {
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });

  }
  openEditingField() {
    this.tr = !this.tr
  }
  showSelectedProvince(selectedValue: any) {
    if (!selectedValue.value || selectedValue.value.length === 0) {
        this.isProvinceSelected = false;
        this.DivisionMultiData = [];
        this.districtModel = [];
        this.selectedDivisionId = null; // Reset selected division
        return;
    }

    // Province is selected; fetch related divisions
    this.isProvinceSelected = true;
    this.genericService.geDivisionDataByMultiProvince(selectedValue.value).subscribe(
        data => {
            this.divisiondefModel = data;

            // Filter active divisions for display
            this.DivisionMultiData = this.divisiondefModel.filter(division => division.IsActive);
            this.selectedDivisionId = null; // Reset division and district when province changes
            this.districtModel = [];
        },
        error => {
            this.Toastr.error("Something went wrong on the server side !!!");
        }
    );
  }
  showSelectedProvince2(selectedValue: any) {
    if (!selectedValue) {
      this.isProvinceSelected = false;
      this.DivisionMultiData = [];
      this.districtModel = [];
      this.selectedDivisionId = null; // Reset selected division
      return;
    }

    // Province is selected; fetch related divisions
    this.isProvinceSelected = true;
    this.genericService.geDivisionDataByMultiProvince(selectedValue).subscribe(
      data => {
        this.divisiondefModel = data;

        // Filter active divisions for display
        this.DivisionMultiData = this.divisiondefModel.filter(division => division.IsActive);
        // this.divisionModel= this.DivisionMultiData
        this.selectedDivisionId = null; // Reset division and district when province changes
        this.districtModel = [];
      },
      error => {
        this.Toastr.error("Something went wrong on the server side !!!");
      }
    );
    this.RHCList = [];
    this.RHCService.getAllRHC(selectedValue,"province").subscribe(data => {
      this.RHCList = data;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  showSelectedDivision(selectedValue: any) {
    if (!selectedValue) {
      this.DistrictsMultiData = [];
      this.isDevisionSelected = false
      // return;
    }

    this.genericService.getDistrictData(selectedValue).subscribe(
      data => {
        this.districtdefModel = data;
        this.isDevisionSelected = true
        this.DistrictsMultiData = this.districtdefModel.filter(district => district.IsActive);
        console.log("Filtered Active Districts:", this.DistrictsMultiData);
      },
      error => {
        this.Toastr.error("Something went wrong on the server side !!!");
      }
    );
    this.RHCList = [];
    this.RHCService.getAllRHC(selectedValue, "division").subscribe(data => {
      this.RHCList = data;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  showselecteddistrictdhq(districtId:any){
    this.RHCList = [];
    this.RHCService.getAllRHC(districtId,"district").subscribe(data => {
      this.RHCList = data;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  ProvinceMultiData: { [key: string]: Object; }[] = [];
  public ProvinceFields: Object = { text: 'ProvinceName', value: 'Id' };
  public ProvincePlaceholder: string = 'Select Province';

  DivisionMultiData: { [key: string]: Object; }[] = [];
  public DivisionFields: Object = { text: 'DivisionName', value: 'Id' };
  public DivisionPlaceholder: string = 'Select Division';

  DistrictsMultiData: { [key: string]: Object; }[] = [];
  public DistrictFields: Object = { text: 'DistrictName', value: 'Id' };
  public DistrictsPlaceholder: string = 'Select Districts';
}
