import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UpdateTHQModel } from '../Model/THQDetailModel';

@Injectable({
  providedIn: 'root'
})
export class THQService {

  constructor(private _http:HttpClient,private router:Router) { }

  getAllTHQ(id: any, idType: 'district' | 'province' | 'division'):Observable<any>{

    const BaseUrl = localStorage.getItem('BaseUrl') + "/api/THQ/GetTHQ";
    const FullUrl = `${BaseUrl}?${idType}Id=${id}`;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }
  // getSelectedDivision(divisionId:number):Observable<any>{

  //   let FullUrl = localStorage.getItem('BaseUrl')+"/api/THQ/GetTHQ?divisionId=" + divisionId;
  //   const headerDict = {
  //     'Access-Control-Allow-Origin':'*',
  //     "Authorization": "Bearer " + localStorage.getItem('access_token'),
  //     'Accept': 'application/json'
  //   }
  //   const requestOptions = {                                                                                                                                                                                 
  //     headers: new HttpHeaders(headerDict), 
  //   };

  //   return this._http.get(FullUrl,requestOptions );
  // }
  getSelectedDistrictTHQ(districtId:number):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/THQ/GetTHQ?districtId=" + districtId;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  getSelectedTHQUserPatients( THQUserPatients:number ):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/THQ/GetTHQReferredPatients?patientStatus="+1+"&thqLoginId=" + THQUserPatients;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  CreateNewTHQ(THQName:string, THQPhone:string,THQAddress:string,Locality1 :number):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/THQ/CreateTHQ";
    let data = {Name:THQName, Contact:THQPhone, Address:THQAddress, DistrictId:Locality1}
    return this._http.post(FullUrl,data,requestOptions);
  }


  // code added for user creation for covid department for all district DHQName:string, DHQPhone:string,DHQAddress:string,Locality1 :number
  CreateCovidTHQUser():Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/THQ/CreateCovidTHQUser";
    //let data = {Name:DHQName, Contact:DHQPhone, Address:DHQAddress, DistrictId:Locality1}data
    return this._http.post(FullUrl,requestOptions);
  }

  UpdateTHQUser(THQUserId:number,Name:string,IsActive:boolean,schedueledetails: any):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/THQ/UpdateTHQUsers";
    let data = {DHQUserId:THQUserId, THQUserName:Name ,IsActive:IsActive, ScheduledDays:schedueledetails}
    return this._http.post(FullUrl,data,requestOptions);
  }


  UpdateTHQ(updateLab: UpdateTHQModel):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/THQ/UpdateTHQ";

    return this._http.post(FullUrl,updateLab,requestOptions);
  }
}
